import React from "react";
import styled from "styled-components";
import '../styles/css-reset.css'
import Brand from "./Brand";

const Container = styled.div`
    margin: 0 auto;    
    max-width: 80em;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
        Helvetica, "Muli", Arial, "Lucida Grande", sans-serif;
    line-height: 1.5;
    padding-top: 2em;
    padding-bottom: 8em;
    padding-left: 2em;
    padding-right: 2em;
`;

export default function Layout({ children }) {
    return <Container><Brand />{children}</Container>;
}

export { Container }