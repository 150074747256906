import styled from 'styled-components';
import { Link } from 'gatsby'

const AnimatedLink = styled(Link)`
    color: hsl(0, 0%, 15%);
    text-decoration: none;
    font-weight: bold;
    display: inline;
    border-bottom: 2px solid transparent;
    :hover {
        border-bottom: 4px solid hsl(153, 54%, 43%);
    }
    transition: border-bottom 0.35s ease 0s;
`;

export default AnimatedLink