import React from "react";
import styled from "styled-components";
import brand from "../../static/brand.png";
import { Link } from "gatsby";

const StyledBrand = styled.img`
    width: 50px;
    height: auto;
    border-radius: 2px;
    box-shadow: 1px 1px 4px gray;
    margin-top: 0.5em;
    animation: slide-down ease 1s;
    @keyframes slide-down {
        0% {
            transform: translateY(-30px);
        }
        100% {
            transform: translateY(0px);
        }
    }
`;

export default function Brand() {
    return (
        <Link to="/">
            <StyledBrand src={brand} alt="Justin Thurman Logo" />
        </Link>
    );
}
