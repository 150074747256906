import React from "react";
import styled from "styled-components";

const StyledPageHeader = styled.h1`
    font-size: 4em;
    color: hsl(0, 0%, 15%);
    padding-top: .3em;
    padding-bottom: .5em;
`;

export default function PageHeader({ children }) {
    return <StyledPageHeader>{children}</StyledPageHeader>;
}
