import React from "react";
import styled, { css } from "styled-components";

const StyledParagraph = styled.p`
    color: hsl(0, 0%, 30%);
    padding-top: 2em;
    max-width: 110ch;
    ${props =>
        props.top &&
        css`
            padding-top: 0em;
        `}
    ${props =>
        props.justified &&
        css`
            text-align: justify;
            text-justify: inter-word;
        `}
`;

export default function Paragraph({ children, top, justified, className }) {
    return <StyledParagraph top={top} justified={justified} className={className}>{children}</StyledParagraph>;
}
